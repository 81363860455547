import React, { Component } from 'react'

class HeroOther extends Component {
	render() {
		const { data, type = '' } = this.props
		if (type === "preview") {
			return (
				<div
					className="full-width-image margin-top-0"
					style={{
						backgroundImage: `url(${data.image})`,
						backgroundPosition: `center center`,
						backgroundSize: 'cover',
						backgroundAttachment: `fixed`,
						height: '700px',
					}}
				>
					<div
						style={{
							display: 'flex',
							height: '150px',
							lineHeight: '1',
							justifyContent: 'space-around',
							alignItems: 'left',
							flexDirection: 'column',
							width: "90%",
						}}
					>
						<div className="has-text-centered">
							<img
								style={{ width: 150 }}
								src={data.midimage.image}
								alt="midimage"
							/>
						</div>
						<div style={{ height: 20, textAlign: `${data.sideimage.align}` }}>
							<img
								style={{ width: 150, marginTop: "-80px" }}
								src={data.sideimage.image}
								alt="sideimage"
							/>
						</div>
						<h1
							className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
							style={{
								color: '#53c5d1',
								lineHeight: '1',
								padding: '0.25em',
								textAlign: 'center',
								textTransform: 'none'
							}}
						>
							{data.title}
						</h1>
						<h1
							className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
							style={{
								color: 'white',
								lineHeight: '1',
								padding: '0.25em',
								textAlign: 'center',
								textTransform: 'none'
							}}
						>
							{data.secondtitle}
						</h1>
					</div>
				</div>
			)
		} else {
			return (
				<div
					className="full-width-image margin-top-0"
					style={{
						backgroundImage: `url(${
							!!data.image.childImageSharp ? data.image.childImageSharp.fluid.src : data.image
							})`,
						backgroundPosition: `center center`,
						backgroundSize: 'cover',
						backgroundAttachment: `fixed`,
						height: '700px',
					}}
				>
					<div
						style={{
							display: 'flex',
							height: '150px',
							lineHeight: '1',
							justifyContent: 'space-around',
							alignItems: 'left',
							flexDirection: 'column',
							width: "90%",
						}}
					>
						<div className="has-text-centered">
							<img
								style={{ width: 150 }}
								src={!!data.midimage.image.childImageSharp ? data.midimage.image.childImageSharp.fluid.src : data.midimage.image}
								alt="midimage"
							/>
						</div>
						<div className="hero-image" style={{ height: 20, textAlign: `${data.sideimage.align}` }}>
							<img
								style={{ width: 150, marginTop: "-80px" }}
								src={!!data.sideimage.image.childImageSharp ? data.sideimage.image.childImageSharp.fluid.src : data.sideimage.image}
								alt="sideimage"
							/>
						</div>
						<h1
							className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
							style={{
								color: '#53c5d1',
								lineHeight: '1',
								padding: '0.25em',
								textAlign: 'center',
								textTransform: 'none!Important;'
							}}
						>
							{data.title}
						</h1>
						<h1
							className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
							style={{
								color: 'white',
								lineHeight: '1',
								padding: '0.25em',
								textAlign: 'center',
								textTransform: 'none!Important;'
							}}
						>
							{data.secondtitle}
						</h1>
					</div>
				</div>
			)
		}
	}
}

export default HeroOther
