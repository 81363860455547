import React, { Component } from 'react'

class Title extends Component {
	render(){
		const { title, color, margin, textAlign='' } = this.props
		return(
			<h1 
	            className="has-text-weight-bold is-size-4-mobile is-size-3-tablet is-size-2-desktop is-size-2-widescreen"
	            style={{color: `${color}`, lineHeight: "1", textAlign: `${textAlign}`, margin: `${margin}`, textTransform: 'uppercase'}}
	        >
	            {title}
          	</h1>
		)
	}
}

export default Title