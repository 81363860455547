import React, { Component } from 'react'

class SubTitle extends Component {
	render() {
		const { title, color, margin, textAlign = "", textTransform = "none!Important;" } = this.props
		return (
			<h2
				className="has-text-weight-bold is-size-5-mobile is-size-4-tablet is-size-4-desktop is-size-4-widescreen"
				style={{ color: `${color}`, lineHeight: "1.5", textAlign: `${textAlign}`, margin: `${margin}`, textTransform: `${textTransform}` }}
			>
				{title}
			</h2>
		)
	}
}

export default SubTitle
