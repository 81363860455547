import React, { Component } from 'react'
import { Link } from 'gatsby'

class AboveFooter extends Component {
	render(){
		const { data, type='' } = this.props
		if(type==='preview'){
			return(
				<div
			      className="full-width-image margin-top-0"
			      style={{
			        backgroundImage: `url(${ data.image })`,
			        backgroundPosition: `center center`,
			        backgroundSize: 'cover',
			        backgroundAttachment: `fixed`,
			        height: `${data.height}`,
			      }}
			    >
			      <div
			        style={{
			          display: 'flex',
			          height: '150px',
			          lineHeight: '1',
			          justifyContent: 'space-around',
			          alignItems: 'left',
			          flexDirection: 'column',
			        }}
			      >
			      	<Link to="/contact">
				        <h1
				          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
				          style={{
				            color: 'white',
				            padding: '0.25em',
				            textAlign: 'center',
				            textTransform: 'uppercase',
				          }}
				        >
				          {data.title}<br />
				          {data.secondtitle}
				        </h1>
				    </Link>
			      </div>
			    </div>
			)
		} else {
			return(
				<div
			      className="full-width-image margin-top-0"
			      style={{
			        backgroundImage: `url(${
			          !!data.image.childImageSharp ? data.image.childImageSharp.fluid.src : data.image
			        })`,
			        backgroundPosition: `center center`,
			        backgroundSize: 'cover',
			        backgroundAttachment: `fixed`,
			        height: `${data.height}`,
			      }}
			    >
			      <div
			        style={{
			          display: 'flex',
			          height: '150px',
			          lineHeight: '1',
			          justifyContent: 'space-around',
			          alignItems: 'left',
			          flexDirection: 'column',
			        }}
			      >
			      	<Link to="/contact">
				        <h1
				          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen"
				          style={{
				            color: 'white',
				            padding: '0.25em',
				            textAlign: 'center',
				            textTransform: 'uppercase',
				          }}
				        >
				          {data.title}<br />
				          {data.secondtitle}
				        </h1>
				    </Link>
			      </div>
			    </div>
			)
		}
	}
}

export default AboveFooter