import React, { Component } from 'react';
import Markdown from 'react-markdown/with-html';

class Descriptions extends Component {
	render() {
		const { descriptions, color, margin } = this.props;

		return (
			<div>
				{
					descriptions.map((description, i) => {
						return (
							<p
								style={{ color: `${color}`, margin: `${margin}` }}
								key={i}>
								<Markdown
									source={description}
									renderers={{ paragraph: 'span', link: props => <a href={props.href} target="_blank" rel="noopener noreferrer" >{props.children}</a> }} />
							</p>
						)

					})
				}
			</div>
		)
	}
}

export default Descriptions
